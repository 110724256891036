@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

::-webkit-scrollbar{
  height:8px;
  width:8px;
}

::-webkit-scrollbar-track{
  background:#f1f1f1
}

::-webkit-scrollbar-thumb{
  background:#c1c1c1;
  border-radius:8px
}

::-webkit-scrollbar-thumb:hover{
  background:#c1c1c1
}

::selection{
  background-color:#3d5469;
  color:#fff
}

html, body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #111827; */
}

body{
  overflow-y: scroll;
}

:root{
  --simacek-color-light: #72bbe6;
  --simacek-color-medium: #007dba;
  --simacek-color-dark: #0b577e;
  --text-dark: #2c303a;
}

body{
  background-color: #f8fafa
}

/* .container { border: 1px solid green !important }
.row { border: 1px solid blue !important }
.col { border: 1px solid red !important } */

div {
  box-sizing: border-box !important;
}

.container{
  @apply mx-auto p-10
}

h1{
  @apply text-6xl font-bold
}

h2{
  @apply text-4xl font-bold
}

h5{
  @apply mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white
}

.lead{
  @apply text-xl my-5 font-medium;
}

.text-gradient{
  background: var(--simacek-color-dark);
  background: repeating-radial-gradient(circle farthest-corner at bottom left, var(--simacek-color-dark) 0%, var(--simacek-color-light) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn {
  @apply inline-flex items-center gap-x-2 px-6 py-4 font-bold text-lg hover:bg-sky-700;
}

.btn-sm{
  @apply font-bold py-2 px-4 rounded-sm;
}

.btn-xs{
  @apply font-semibold py-1 px-4 text-base text-white mb-1
}
.btn-icon{
  @apply text-sm font-medium my-2 mx-3
}

.btn-blue {
  @apply text-white;
  background-color: var(--simacek-color-medium);
  transition: all 0.15s ease;
}

.btn-red {
  @apply text-white;
  background-color: #e53e3e;
  transition: all 0.15s ease;
}

button[disabled]{
  @apply hover:cursor-not-allowed opacity-50 hover:opacity-50
}

p{
  @apply text-lg my-3
}

.disclosed-menu-btn{
  @apply inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
}

.disclosed-btn{
  @apply block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white
}

.header-input{
  @apply block w-full rounded-md border-0 bg-gray-700 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6
}

.muted-text{
  @apply whitespace-nowrap px-3 py-4 text-sm text-gray-500
}


strong{ @apply font-bold }
i{ font-style: italic }

.newsContent ul li{
  margin: 0.5rem 0 0.5rem 2rem;
  list-style: disc;
}

.newsContent ol li{
  margin: 0.5rem 0 0.5rem 2rem;
  list-style: decimal;
}

code{
  @apply bg-gray-200 text-gray-900 rounded-md p-0.5 px-1 text-sm font-medium mr-2
}

pre{
  @apply bg-gray-200 text-gray-900 rounded-md p-3 px-5 text-sm font-medium overflow-x-auto
}

.table-card{
  @apply min-w-full divide-y divide-gray-300;
}

.table-card thead{ @apply bg-gray-50 }

.table-card th{
  @apply px-3 py-3.5 text-left text-sm font-semibold text-gray-900;
}

.table-card tbody{
  @apply divide-y divide-gray-200 bg-white
}

.table-card td{
  @apply px-3 py-4 text-sm text-gray-500;
}

.table-card tr{
  @apply hover:bg-gray-50
}

.table-card p{
  margin: 0; padding: 0; 
  font-size: 0.95rem;
  line-height: 1.4rem; 
}

code{
  font-family: 'Fira Code', monospace;
  @apply bg-gray-200 text-gray-900 rounded-none p-0.5 px-2 text-sm font-medium;
}

.nav-inactive{
  @apply rounded-md px-3 py-2 text-sm font-medium text-white;
}

.nav-active{
  @apply rounded-md px-3 py-2 text-sm font-medium text-white bg-gray-700
}

.badge{
  @apply inline-flex items-center px-2.5 py-0.5 rounded-sm text-xs font-medium bg-gray-100 text-gray-800
}

.badge-simacek{
  @apply bg-blue-100 text-blue-800
}

.badge-contento{
  @apply bg-orange-100 text-orange-800
}

article{
  @apply bg-white shadow-sm hover:shadow-md transition-all p-5
}

article h3{
  @apply text-lg uppercase font-semibold leading-6 text-gray-900
}

article p{
  @apply mt-2 text-sm text-gray-500
}

article ul, article ol{
  @apply mt-2 text-sm text-gray-500 list-disc list-inside ml-4
}

article ul li, article ol li{
  @apply mt-2 text-sm text-gray-500
}

article table{
  @apply w-full
}

article table, article table th, article table td{ 
  @apply mt-2 text-sm text-gray-500 border-collapse border border-slate-300
}

article table td{ 
  @apply px-3 py-2
}

.post-content{
  @apply mx-10 my-10;
  @apply text-lg
}

.post-content h1{
  @apply text-3xl  mb-8 mt-8
}

.post-content h2{
  @apply text-2xl uppercase mb-5 mt-5
}

.post-content h3{
  @apply text-xl uppercase mb-3 mt-3
}

.post-content .meta{
  @apply text-sm text-gray-500
}

article p a{
  @apply text-blue-500 hover:text-blue-700 underline underline-offset-2
}

.modal input, .modal select, .modal textarea{
  @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6
}

.modal input[type="checkbox"]{
  @apply rounded text-blue-600 focus:ring-0
}

.modal input:disabled{
  @apply bg-gray-100 text-gray-500
}

.modal textarea{
  @apply resize-none
}

.custom-warning{
  @apply bg-red-100 py-1.5 px-3 font-semibold text-red-500 rounded-md
}

.min-w-50{
  min-width: 50%
}

#content{
  height: 200px;
  margin-bottom: 20px;
}

.modal-header{
  @apply bg-gray-100 px-5 py-5
}

.modal-body{
  @apply bg-white px-5 py-5
}

.required{
  @apply text-red-500
}
/* Green */
.bg-green-100 {
  background-color: #f0fdf4;
}

.text-green-800 {
  color: #22543d;
}

/* Blue */
.bg-blue-100 {
  background-color: #ebf8ff;
}

.text-blue-800 {
  color: #2a4365;
}

/* Teal */
.bg-teal-100 {
  background-color: #e6fffa;
}

.text-teal-800 {
  color: #234e52;
}

/* Orange */
.bg-orange-100 {
  background-color: #fff7ed;
}

.text-orange-800 {
  color: #7b341e;
}

/* Red */
.bg-red-100 {
  background-color: #fef2f2;
}

.text-red-800 {
  color: #7b1d1d;
}

/* Yellow */
.bg-yellow-100 {
  background-color: #fffff0;
}

.text-yellow-800 {
  color: #7c7425;
}

.Typewriter{
  display: inline
}